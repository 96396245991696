@font-face {
  font-family: "iconfont"; /* Project id 2352390 */
  src: url('iconfont.woff2?t=1662105136896') format('woff2'),
       url('iconfont.woff?t=1662105136896') format('woff'),
       url('iconfont.ttf?t=1662105136896') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Steam:before {
  content: "\e6c6";
}

.icon-icon-test:before {
  content: "\e60d";
}

.icon-Gas-Container:before {
  content: "\e69a";
}

.icon-oxygen:before {
  content: "\e618";
}

.icon-fire:before {
  content: "\e605";
}

.icon-checklist:before {
  content: "\e604";
}

.icon-CHECKLIST:before {
  content: "\e9fc";
}

.icon-water:before {
  content: "\e846";
}

.icon-bx-water:before {
  content: "\e68a";
}

.icon-warning:before {
  content: "\e96b";
}

.icon-folder-broken:before {
  content: "\e7bd";
}

.icon-unlink1:before {
  content: "\e72f";
}

.icon-link:before {
  content: "\e771";
}

.icon-Graph:before {
  content: "\e635";
}

.icon-Marker:before {
  content: "\e638";
}

.icon-pressureSensor:before {
  content: "\e616";
}

.icon-pressure:before {
  content: "\e715";
}

.icon-pressurechecker:before {
  content: "\ebaf";
}

.icon-tecbatterycha1:before {
  content: "\f4f0";
}

.icon-tecbatterycha2:before {
  content: "\f4f2";
}

.icon-tecbatterycha3:before {
  content: "\f4f3";
}

.icon-ProfileHili:before {
  content: "\e617";
}

.icon-ask:before {
  content: "\e660";
}

.icon-transaction:before {
  content: "\e6c2";
}

.icon-iconfinder__:before {
  content: "\e607";
}

.icon-Organization-Data-report-32:before {
  content: "\e686";
}

.icon-transaction1:before {
  content: "\e60c";
}

.icon-ReservationInstallation:before {
  content: "\e62d";
}

.icon-the-installation-work-order:before {
  content: "\ea73";
}

.icon-tenant:before {
  content: "\f043";
}

.icon-internalworkexperience:before {
  content: "\e62c";
}

.icon-btn_Search_documents:before {
  content: "\e693";
}

.icon-location:before {
  content: "\e630";
}

.icon-iconlocation:before {
  content: "\e65e";
}

.icon-setup_bida:before {
  content: "\e61c";
}

.icon-location1:before {
  content: "\e61d";
}

.icon-location2:before {
  content: "\e603";
}

.icon-setupqueue:before {
  content: "\e700";
}

.icon-attendancelocation:before {
  content: "\e66a";
}

.icon-data_setup:before {
  content: "\e67b";
}

.icon-expandmore:before {
  content: "\e75a";
}

.icon-expandless:before {
  content: "\e727";
}

.icon-logoutclosesignoutexitaccess:before {
  content: "\e62b";
}

.icon-Expanddownward:before {
  content: "\e64d";
}

.icon-Expandupward:before {
  content: "\e670";
}

.icon-usersetting:before {
  content: "\e6b3";
}

.icon-area:before {
  content: "\e717";
}

.icon-dashboard:before {
  content: "\e781";
}

.icon-node4:before {
  content: "\e602";
}

.icon-node3:before {
  content: "\e601";
}

.icon-tecbatterycha:before {
  content: "\f4f1";
}

.icon-gateway:before {
  content: "\e669";
}

.icon-node1:before {
  content: "\e60f";
}

.icon-e-tilt-anomaliy-o:before {
  content: "\e63b";
}

.icon-node:before {
  content: "\e600";
}

.icon-meter:before {
  content: "\e6ae";
}

.icon-pulse:before {
  content: "\ea1f";
}

